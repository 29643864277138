.panel-pmnt .card-container .form-group {
  display: block;
  width: 400px;
}

.panel-pmnt .card-container label.form-check-label {
  width: 150px;
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 15px;
}

.panel-pmnt .credit-card-mock {
  border: 1px solid rgba(218, 169, 169, 0.204);
  width: 450px;
  height: 350px;
}

.panel-pmnt .credit-card-mock label {
  width: 100px;
  font-weight: 300;
  font-size: 15px;
  letter-spacing: -0.5px;
  text-align: left;
}

.form-check .cc-input {
  width: 200px;
  text-align: left;
  height: 36px;
  font-size: 13px;
  margin-left: 10px;
  position: relative;
}

.form-check .cc-input#cnumber,
.form-check .cc-input#cardholder,
.form-check .cc-input#exp_month,
.form-check .cc-input#exp_year,
.form-check .cc-input#cvv {
  background-color: rgba(226, 223, 195, 0.5);
  border: 1px solid grey;
  padding-left: 10px;
}

.form-check .cc-input#exp_month,
.form-check .cc-input#exp_year,
.form-check .cc-input#cvv {
  width: 80px;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
          animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}