.panel-invoice {
  max-width: 550px;
}

div.panel-invoice {
  width: 90%;
}
.row-void span {
  text-transform: uppercase;
  font-weight: 100;
}
td {
  border: 1px solid grey;
  width: 25%;
  font-weight: 100;
  text-transform: uppercase;
  padding: 9px;
  font-size: 13px;
}
td.txnValue {
  width: 75%;
  font-size: 13px;
  font-weight: 900;
}
td span.charge {
  font-size: 52px;
}
td textarea {
  font-size: 12px;
  width: 100%;
  height: 80px;
}
.invoice-uuid {
  font-size: small;
  color: gray;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
