.panel-pmnt .btn {
  display: block;
}

.card-select {
  height: 500px;
}

.card-select-form {
  height: 350px;
}
