.bg-img {
  background-size: cover;
  background-position: center;
}
.terminal {
  min-height: 100vh;
  flex-direction: row;
}
.terminal img.logo {
  width: 100px;
}
.panel-txn {
  width: 45%;
  height: 100%;
  min-width: 550px;
  height: 100vh;
  min-height: 700px;
  background-color: #d5a000;
  background-size: cover;
  background-position: bottom center;
  background-image: url("../../assets/img/banner-bg.jpg");
}

.panel-pmnt {
  width: 55%;
  min-width: 450px;
}

.terminal .cp-logo {
  width: 150px;
  opacity: 0.4;
  display: blocks;
  position: fixed;
  right: 40px;
  bottom: 30px;
}

@media screen and (max-width: 1100px) {
  .terminal {
    flex-direction: column;
  }
  .panel-txn,
  .panel-pmnt {
    width: 100vw;
  }
}
