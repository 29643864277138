.bg-img {
  background-size: cover;
  background-position: center;
}
.error-screen img.logo {
  width: 100px;
  position: absolute;
  top: 50px;
  left: 50px;
}
.error-screen {
  width: 45%;
  height: 100%;
  min-width: 550px;
  height: 100vh;
  min-height: 700px;
  background-color: #d5a000;
  background-size: cover;
  background-position: bottom center;
  background-image: url("../../assets/img/banner-bg.jpg");
}

.error-div {
  border: 1px solid black;
  box-shadow: 1px 1px 10px black;
}
